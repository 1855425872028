import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { trimTextContentFromAnchor } from "@lexical/selection";
import { $getSelection, $isRangeSelection, RootNode } from "lexical";
import { useEffect, useState } from "react";

/**
 * Most of the code has been copied from here:
 * https://github.com/facebook/lexical/pull/2254
 *  */

export function MaxLengthPlugin({
  maxLength
}: {
  maxLength: number;
}) {
  const [editor] = useLexicalComposerContext();
  const [charactersCount, setCharactersCount] = useState(0);
  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevTextContent = editor.getEditorState().read(() => rootNode.getTextContent());
      const textContent = rootNode.getTextContent();
      setCharactersCount(textContent.length);
      if (prevTextContent !== textContent) {
        const textLength = textContent.length;
        const delCount = textLength - maxLength;
        const anchor = selection.anchor;
        if (delCount > 0) {
          trimTextContentFromAnchor(editor, anchor, delCount);
        }
      }
    });
  }, [editor, maxLength]);
  return <p className="bottom-0 py-1 border-neutral-200 border-t w-full flex justify-end items-center text-neutral-500 px-2" data-sentry-component="MaxLengthPlugin" data-sentry-source-file="max-length-plugin.tsx">
      <span className="text-neutral-600 text-xs font-semibold leading-none">{charactersCount}</span> /
      <span className="text-xs font-semibold leading-none">{maxLength}</span>
    </p>;
}